var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns is-multiline is-gapless"},[_c('div',{staticClass:"column is-12"},[(_vm.data.isButton)?[_c('ValidationProvider',{attrs:{"vid":("checkboxtype" + _vm.indexSection + _vm.indexQuestion),"name":"checkbox","rules":("" + (_vm.checkRulesData(
              _vm.data.isRequired,
              _vm.data.leftSideNumber,
              _vm.data.rightSideNumber
            )))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{staticClass:"columns image-selector is-gapless is-multiline"},_vm._l((_vm.data.answerList),function(option,indexOption){return _c('div',{key:indexOption,class:option.type == 'text' ? 'column' : 'column is-narrow'},[(option.type == 'text')?[_c('b-checkbox-button',{staticClass:"my-radio-text",attrs:{"type":"is-hcc","native-value":option.id,"disabled":_vm.isDisabledButton && !_vm.checkboxSelected.includes(option.id)},on:{"copy":function($event){return _vm.copyText()},"input":function($event){return _vm.checkRulesCheckbox(option)}},model:{value:(_vm.checkboxSelected),callback:function ($$v) {_vm.checkboxSelected=$$v},expression:"checkboxSelected"}},[_vm._v(" "+_vm._s(option.description)+" ")])]:(option.type == 'image')?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkboxSelected),expression:"checkboxSelected"},{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticStyle:{"margin-right":"10px"},attrs:{"id":option.id,"type":"checkbox","disabled":_vm.isDisabledButton && !_vm.checkboxSelected.includes(option.id)},domProps:{"value":option.id,"checked":Array.isArray(_vm.checkboxSelected)?_vm._i(_vm.checkboxSelected,option.id)>-1:(_vm.checkboxSelected)},on:{"change":[function($event){var $$a=_vm.checkboxSelected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=option.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkboxSelected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkboxSelected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkboxSelected=$$c}},function($event){return _vm.checkRulesCheckbox(option)}]}}),_c('label',{staticClass:"my-radio-image",staticStyle:{"max-height":"250px","max-width":"250px","background-size":"auto","object-fit":"cover"},style:(_vm.getSizeImage(option.id) >= 250
                      ? 'width: 100%;'
                      : 'width: auto;'),attrs:{"for":option.id}},[_c('img',{staticStyle:{"max-width":"250px"},attrs:{"id":("image-" + (option.id)),"src":_vm.getUrlFile(option.imagePath),"alt":""}})])]:_vm._e()],2)}),0),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3319908308)})]:[_c('ValidationProvider',{attrs:{"vid":("checkboxtype" + _vm.indexSection + _vm.indexQuestion),"name":"checkbox","rules":("" + (_vm.checkRulesData(
              _vm.data.isRequired,
              _vm.data.leftSideNumber,
              _vm.data.rightSideNumber
            )))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{style:(_vm.differentiateStyle(_vm.data.answerList))},_vm._l((_vm.data.answerList),function(option,indexOption){return _c('div',{key:indexOption},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12 image-selector"},[(option.type == 'text')?[_c('b-checkbox',{attrs:{"type":"is-hcc","native-value":option.id,"disabled":_vm.isDisabledButton &&
                          !_vm.checkboxSelected.includes(option.id)},on:{"input":function($event){return _vm.checkRulesCheckbox(option)},"copy":function($event){return _vm.copyText()}},model:{value:(_vm.checkboxSelected),callback:function ($$v) {_vm.checkboxSelected=$$v},expression:"checkboxSelected"}},[_vm._v(" "+_vm._s(option.description)+" ")])]:(option.type == 'image')?[_c('b-checkbox',{attrs:{"type":"is-hcc","native-value":option.id,"disabled":_vm.isDisabledButton &&
                          !_vm.checkboxSelected.includes(option.id)},on:{"input":function($event){return _vm.checkRulesCheckbox(option)}},model:{value:(_vm.checkboxSelected),callback:function ($$v) {_vm.checkboxSelected=$$v},expression:"checkboxSelected"}},[_c('img',{style:(_vm.getSizeImage(option.id) >= 250
                            ? 'width: 200px;'
                            : 'width: auto;'),attrs:{"src":_vm.getUrlFile(option.imagePath),"alt":"Image"}})])]:_vm._e()],2)])])}),0),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}])})]],2),(_vm.data.isExample && _vm.checkboxSelected.length > 0)?[(_vm.isAnswerTrue)?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.trueAnswer)},on:{"copy":function($event){return _vm.copyText()}}})]):_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.falseAnswer)},on:{"copy":function($event){return _vm.copyText()}}})])]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }