<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="columns">
          <div class="column is-2"></div>
          <div class="column is-8">
            <template v-if="data.leftSideNumber == 0">
              <div class="columns">
                <div
                  v-for="(number, index) in data.rightSideNumber + 1"
                  :key="index"
                  class="column"
                >
                  <b-field grouped position="is-centered" @copy="copyText()">
                    {{ index }}
                  </b-field>
                </div>
              </div>
            </template>
            <template v-else-if="data.leftSideNumber == 1">
              <div class="columns">
                <div
                  v-for="(number, index) in data.rightSideNumber"
                  :key="index"
                  class="column"
                >
                  <b-field grouped position="is-centered" @copy="copyText()">
                    {{ number }}
                  </b-field>
                </div>
              </div>
            </template>
          </div>
          <div class="column is-2"></div>
        </div>
        <ValidationProvider
          :vid="`linearscaletype${indexSection}${indexQuestion}`"
          name="Linear Scale"
          :rules="`${data.isRequired ? 'required' : ''}`"
          v-slot="{ errors }"
        >
          <div class="columns is-multiline">
            <div class="column is-2">
              <div class="level">
                <div class="level-left"></div>
                <div class="level-right" @copy="copyText()">
                  {{ data.leftSide }}
                </div>
              </div>
            </div>
            <div class="column is-8">
              <template v-if="data.leftSideNumber == 0">
                <div class="columns">
                  <div
                    v-for="(number, index) in data.rightSideNumber + 1"
                    :key="index"
                    class="column"
                  >
                    <b-field grouped position="is-centered">
                      <b-radio
                        class="scale-width"
                        type="is-hcc"
                        v-model="linearScale"
                        :native-value="index"
                      ></b-radio>
                    </b-field>
                  </div>
                </div>
              </template>
              <template v-else-if="data.leftSideNumber == 1">
                <div class="columns">
                  <div
                    v-for="(number, index) in data.rightSideNumber"
                    :key="index"
                    class="column"
                  >
                    <b-field grouped position="is-centered">
                      <b-radio
                        class="scale-width"
                        type="is-hcc"
                        v-model="linearScale"
                        :native-value="number"
                      ></b-radio>
                    </b-field>
                  </div>
                </div>
              </template>
            </div>
            <div class="column is-2" @copy="copyText()">
              {{ data.rightSide }}
            </div>
            <div class="required column is-12">{{ errors[0] }}</div>
          </div>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
    indexSection: {
      default: () => {},
    },
    indexQuestion: {
      default: () => {},
    },
    sectionId: {
      default: () => {},
    },
  },
  data() {
    return {
      linearScale: null,
      isLoad: false,
    }
  },
  mounted() {
    this.isLoad = true
    this.linearScale =
      this.data.answerList[0].description == null
        ? null
        : parseInt(this.data.answerList[0].description)
    if (this.linearScale == null) {
      this.isLoad = false
    }
  },
  watch: {
    linearScale() {
      if (!this.isLoad) {
        this.submit()
      } else {
        this.isLoad = false
      }
    },
  },
  methods: {
    submit() {
      let data = {
        newSectionId: this.sectionId,
        newQuestionId: this.data.id,
        newAnswerId: this.data.answerList[0].id,
        description: null,
        rating: this.linearScale,
        indexQuestion: 0,
        indexAnswer: 0,
      }

      this.$emit('clicked', data)
    },
    copyText() {
      const selection = document.getSelection()

      this.$emit('copy-text', selection.toString())
    },
  },
}
</script>
<style>
.scale-width {
  width: 10px !important;
}
</style>
