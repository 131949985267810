var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns is-multiline is-gapless"},[_c('div',{staticClass:"column is-12"},[(_vm.data.isButton)?[_c('ValidationProvider',{attrs:{"vid":("radiobuttontype" + _vm.indexSection + _vm.indexQuestion),"name":"Radio Button","rules":("" + (_vm.data.isRequired ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"columns is-multiline is-gapless image-selector"},_vm._l((_vm.data.answerList),function(option,indexOption){return _c('div',{key:indexOption,class:option.type == 'text' ? 'column' : 'column is-narrow'},[(option.type == 'text')?[_c('b-radio-button',{attrs:{"type":"is-hcc","size":"is-medium","outlined":"","expanded":"","native-value":option.id},on:{"copy":function($event){return _vm.copyText()},"input":function($event){return _vm.checkRulesRadioButton(option)},"copy-text":function($event){return _vm.copyText('copy', $event)}},model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}},[_vm._v(" "+_vm._s(option.description)+" ")])]:(option.type == 'image')?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOption),expression:"selectedOption"},{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"id":option.id,"type":"radio"},domProps:{"value":option.id,"checked":_vm._q(_vm.selectedOption,option.id)},on:{"input":function($event){return _vm.checkRulesRadioButton(option)},"change":function($event){_vm.selectedOption=option.id}}}),_c('label',{staticClass:"my-radio-image",staticStyle:{"max-height":"250px","max-width":"250px","background-size":"auto","object-fit":"cover"},style:(_vm.getSizeImage(option.id) >= 250
                      ? 'width: 100%;'
                      : 'width: auto;'),attrs:{"for":option.id}},[_c('img',{staticStyle:{"max-width":"250px"},attrs:{"id":("image-" + (option.id)),"src":_vm.getUrlFile(option.imagePath),"alt":""}})])]:_vm._e()],2)}),0),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,109132160)})]:[_c('ValidationProvider',{attrs:{"vid":("radiobuttontype" + _vm.indexSection + _vm.indexQuestion),"name":"Radio Button","rules":("" + (_vm.data.isRequired ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('div',{style:(_vm.differentiateStyle(_vm.data.answerList))},_vm._l((_vm.data.answerList),function(option,indexOption){return _c('div',{key:indexOption},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12 image-selector"},[(option.type == 'text')?[_c('b-radio',{attrs:{"type":"is-hcc","native-value":option.id},on:{"input":function($event){return _vm.checkRulesRadioButton(option)}},model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}},[_vm._v(" "+_vm._s(option.description)+" ")])]:(option.type == 'image')?[_c('b-radio',{attrs:{"type":"is-hcc","native-value":option.id},on:{"input":function($event){return _vm.checkRulesRadioButton(option)}},model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}},[_c('img',{style:(_vm.getSizeImage(option.id) >= 200
                            ? 'width: 200px;'
                            : 'width: auto;'),attrs:{"src":_vm.getUrlFile(option.imagePath),"alt":"Image"}})])]:_vm._e()],2)])])}),0),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}])})]],2),(_vm.data.isExample && _vm.selectedOption)?[(_vm.isAnswerTrue)?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.trueAnswer)},on:{"copy":function($event){return _vm.copyText()}}})]):_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.falseAnswer)},on:{"copy":function($event){return _vm.copyText()}}})])]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }