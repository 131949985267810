<template>
  <div>
    <div class="columns is-multiline is-gapless">
      <div class="column is-12">
        <!-- {{ checkboxSelected }} -->
        <template v-if="data.isButton">
          <ValidationProvider
            :vid="`checkboxtype${indexSection}${indexQuestion}`"
            name="checkbox"
            :rules="
              `${checkRulesData(
                data.isRequired,
                data.leftSideNumber,
                data.rightSideNumber
              )}`
            "
            v-slot="{ errors }"
          >
            <div class="columns image-selector is-gapless is-multiline">
              <div
                v-for="(option, indexOption) in data.answerList"
                :key="indexOption"
                :class="option.type == 'text' ? 'column' : 'column is-narrow'"
              >
                <template v-if="option.type == 'text'">
                  <b-checkbox-button
                    type="is-hcc"
                    v-model="checkboxSelected"
                    :native-value="option.id"
                    class="my-radio-text"
                    @copy="copyText()"
                    @input="checkRulesCheckbox(option)"
                    :disabled="
                      isDisabledButton && !checkboxSelected.includes(option.id)
                    "
                  >
                    {{ option.description }}
                  </b-checkbox-button>
                </template>
                <template v-else-if="option.type == 'image'">
                  <input
                    :id="option.id"
                    type="checkbox"
                    v-model="checkboxSelected"
                    :value="option.id"
                    @change="checkRulesCheckbox(option)"
                    :disabled="
                      isDisabledButton && !checkboxSelected.includes(option.id)
                    "
                    style="margin-right: 10px"
                    v-show="false"
                  />
                  <label
                    class="my-radio-image"
                    :for="option.id"
                    style="
                      max-height: 250px;
                      max-width: 250px;
                      background-size: auto;
                      object-fit: cover;
                    "
                    :style="
                      getSizeImage(option.id) >= 250
                        ? 'width: 100%;'
                        : 'width: auto;'
                    "
                  >
                    <img
                      :id="`image-${option.id}`"
                      :src="getUrlFile(option.imagePath)"
                      alt=""
                      style="max-width: 250px;"
                    />
                  </label>
                </template>
              </div>
            </div>
            <span class="required">{{ errors[0] }}</span>
          </ValidationProvider>
        </template>
        <template v-else>
          <ValidationProvider
            :vid="`checkboxtype${indexSection}${indexQuestion}`"
            name="checkbox"
            :rules="
              `${checkRulesData(
                data.isRequired,
                data.leftSideNumber,
                data.rightSideNumber
              )}`
            "
            v-slot="{ errors }"
          >
            <div :style="differentiateStyle(data.answerList)">
              <div
                v-for="(option, indexOption) in data.answerList"
                :key="indexOption"
              >
                <div class="columns">
                  <div class="column is-12 image-selector">
                    <template v-if="option.type == 'text'">
                      <b-checkbox
                        type="is-hcc"
                        v-model="checkboxSelected"
                        :native-value="option.id"
                        @input="checkRulesCheckbox(option)"
                        @copy="copyText()"
                        :disabled="
                          isDisabledButton &&
                            !checkboxSelected.includes(option.id)
                        "
                      >
                        {{ option.description }}
                      </b-checkbox>
                    </template>
                    <template v-else-if="option.type == 'image'">
                      <b-checkbox
                        type="is-hcc"
                        v-model="checkboxSelected"
                        :native-value="option.id"
                        @input="checkRulesCheckbox(option)"
                        :disabled="
                          isDisabledButton &&
                            !checkboxSelected.includes(option.id)
                        "
                      >
                        <img
                          :src="getUrlFile(option.imagePath)"
                          alt="Image"
                          :style="
                            getSizeImage(option.id) >= 250
                              ? 'width: 200px;'
                              : 'width: auto;'
                          "
                        />
                      </b-checkbox>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <span class="required">{{ errors[0] }}</span>
          </ValidationProvider>
        </template>
      </div>

      <!-- For True Answer -->
      <template v-if="data.isExample && checkboxSelected.length > 0">
        <div v-if="isAnswerTrue">
          <div @copy="copyText()" v-html="data.trueAnswer"></div>
        </div>
        <div v-else>
          <div @copy="copyText()" v-html="data.falseAnswer"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
    indexSection: {
      default: () => {},
    },
    indexQuestion: {
      default: () => {},
    },
    sectionId: {
      default: () => {},
    },
    type: {
      default: () => {},
    },
  },
  data() {
    return {
      checkboxSelected: [],
      isDisabledButton: false,
      isAnswerTrue: false,
      isLoad: false,
      arrayTrueAnswer: [],
    }
  },
  mounted() {
    this.isLoad = true
    this.data.answerList.map(answer => {
      if (answer.isAnswer == true) {
        this.checkboxSelected.push(answer.id)
      }
      if (answer.isValid == true) {
        this.arrayTrueAnswer.push(answer.id)
      }
      if (
        JSON.stringify(this.checkboxSelected.sort()) ==
        JSON.stringify(this.arrayTrueAnswer)
      ) {
        this.isAnswerTrue = true
      } else {
        this.isAnswerTrue = false
      }
    })

    if (this.checkboxSelected.length == 0) {
      this.isLoad = false
    }
  },
  // watch: {
  //   checkboxSelected(val) {
  //     console.log('val : ')
  //     console.log(val)
  //     console.log(this.isLoad)
  //     if (!this.isLoad) {
  //       console.log('4')
  //       // this.submit(val)
  //     } else {
  //       this.isLoad = false
  //     }
  //   },
  // },
  methods: {
    submit(val) {
      let data = {
        newSectionId: this.sectionId,
        newQuestionId: this.data.id,
        newAnswerId: val,
        description: null,
        rating: 0,
        indexQuestion: 0,
        indexAnswer: 0,
      }

      this.$emit('clicked', data)
    },
    getUrlFile(file) {
      return process.env.VUE_APP_BACKEND_URL + file
    },
    getSizeImage(id) {
      let image = document.getElementById('image-' + id)

      if (image != null) {
        image = image.naturalWidth
      }

      return image
    },
    differentiateStyle(arr) {
      let type = arr[0].type
      if (type == 'image') {
        return 'display:flex'
      } else {
        return ''
      }
    },
    copyText() {
      const selection = document.getSelection()

      this.$emit('copy-text', selection.toString())
    },
    checkRulesCheckbox() {
      if (this.data.rightSideNumber != null) {
        if (this.checkboxSelected.length >= this.data.rightSideNumber) {
          this.isDisabledButton = true
        } else {
          this.isDisabledButton = false
        }
      } else {
        this.isDisabledButton = false
      }

      if (
        JSON.stringify(this.checkboxSelected.sort()) ==
        JSON.stringify(this.arrayTrueAnswer)
      ) {
        this.isAnswerTrue = true
      } else {
        this.isAnswerTrue = false
      }
      this.submit(this.checkboxSelected)
    },
    checkRulesData(isRequired, minimumNumber, maximumNumber) {
      let rules = ``
      if (isRequired) {
        rules = `requiredarray`
      }

      if (minimumNumber != null) {
        if (rules != ``) {
          rules = rules + `|`
        }
        rules = rules + `checkboxmin:${minimumNumber}`
      }

      if (maximumNumber != null) {
        if (rules != ``) {
          rules = rules + `|`
        }
        rules = rules + `checkboxmax:${maximumNumber}`
      }

      return rules
    },
  },
}
</script>

<style scoped>
.columns div.column input {
  margin-top: -6rem;
}

.image-selector div input {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.image-selector div input:active + .my-radio-image {
  opacity: 0.9;
}
.image-selector div input:checked + .my-radio-image {
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
  outline: 5px solid #00a3a3;
  outline-offset: -4px;
  height: auto;
}
.my-radio-image {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 100px;
  height: 70px;
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  -webkit-filter: grayscale(1) opacity(0.7);
  -moz-filter: grayscale(1) opacity(0.7);
  filter: grayscale(1) opacity(0.7);
}
/* @media screen and (min-width: 1216px) {
  .my-radio-image {
    height: 200px !important;
  }
}
@media screen and (min-width: 1408px) {
  .my-radio-image {
    height: 300px !important;
  }
} */
.my-radio-image:hover {
  -webkit-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  -moz-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  outline: 5px solid #00a3a3;
  height: auto;
}

.my-radio-text {
  height: auto;
  white-space: normal;
}

/* Extras */
a:visited {
  color: #888;
}
a {
  color: #444;
  text-decoration: none;
}
p {
  margin-bottom: 0.3em;
}

.image-selector div label {
  margin-left: 7px;
}
span.cc {
  color: #6d84b4;
}
</style>
