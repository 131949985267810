<template>
  <div>
    <template v-if="!data.isExample">
      <ValidationProvider
        :vid="`textType${indexSection}${indexQuestion}`"
        name="Text"
        :rules="{ required: data.isRequired }"
        v-slot="{ errors }"
      >
        <b-field :type="errors.length > 0 ? 'is-danger' : null">
          <b-input
            type="textarea"
            v-model="description"
            @blur="submit()"
            @copy.native="copyText"
            @paste.native="pasteText"
          ></b-input>
        </b-field>
        <span class="required">{{ errors[0] }}</span>
      </ValidationProvider>
    </template>
    <template v-else>
      {{ data.answerList[0].description }}
    </template>
  </div>
</template>

<script>
export default {
  name: 'answer-type-essay',
  props: {
    data: {
      default: () => {},
    },
    indexSection: {
      default: () => {},
    },
    sectionId: {
      default: () => {},
    },
    indexQuestion: {
      default: () => {},
    },
  },
  data() {
    return {
      description: null,
      isLoad: false,
    }
  },
  mounted() {
    this.isLoad = true
    this.description = this.data.answerList[0].description
    if (this.description == null) {
      this.isLoad = false
    }
  },
  methods: {
    submit() {
      let data = {
        newSectionId: this.sectionId,
        newQuestionId: this.data.id,
        newAnswerId: this.data.answerList[0].id,
        description: this.description,
        rating: 0,
        indexQuestion: 0,
        indexAnswer: 0,
      }

      this.$emit('clicked', data)
    },
    copyText() {
      const selection = document.getSelection()

      this.$emit('copy-text', selection.toString())
    },
    pasteText(event) {
      this.$emit('paste-text', event.target.value)
    },
    copyTextData(event) {
      this.$emit('copy-text', event.target.value)
    },
  },
}
</script>
