<template>
  <div>
    <div class="message-body-data" @copy="copyText()">
      Klik tahan pilihan dari kotak sebelah kiri, dan pindahkan ke kotak sebelah
      kanan. Setelah itu, urutkan pilihan Anda.
    </div>
    <ValidationProvider
      :vid="`rankingtype${indexSection}${indexQuestion}`"
      name="Ranking"
      :rules="
        `${checkRulesData(
          data.isRequired,
          data.leftSideNumber,
          data.rightSideNumber
        )}`
      "
      v-slot="{ errors }"
      class="columns is-multiline is-mobile"
      style="margin: 0.1px"
    >
      <!-- For list question -->
      <draggable
        class="column is-6 border-ranking-left is-variable list-group"
        :list="rankingOption"
        group="ranking1"
      >
        <div
          v-for="(ranking, indexRanking) in rankingOption"
          :key="indexRanking"
          style="padding-bottom: 2%"
        >
          <div class="box-answer">
            <div class="columns is-mobile">
              <div
                class="column is-11"
                @copy="copyText()"
                style="word-wrap: break-word"
              >
                {{ ranking.description }}
              </div>
              <div class="column is-1" style="margin: auto">
                <b-icon
                  size="is-small"
                  icon="share"
                  style="vertical-align: middle"
                ></b-icon>
              </div>
            </div>
          </div>
        </div>
      </draggable>

      <!-- For list answer -->
      <draggable
        v-model="answerRanking"
        class="column is-6 border-ranking-right is-variable list-group"
        group="ranking1"
      >
        <div
          v-for="(ranking, indexRanking) in answerRanking"
          :key="indexRanking"
          style="padding-bottom: 2%"
        >
          <div class="box-answer">
            <div class="columns is-mobile">
              <div
                class="column is-11"
                @copy="copyText()"
                style="word-wrap: break-word"
              >
                {{ indexRanking + 1 }}. {{ ranking.description }}
              </div>
              <div class="column is-1" style="margin: auto">
                <b-icon
                  size="is-small"
                  icon="sort"
                  style="vertical-align: middle"
                ></b-icon>
              </div>
            </div>
          </div>
        </div>
      </draggable>
      <div class="required columns is-12">
        <div class="column is-12">
          {{ errors[0] }}
        </div>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
  },
  props: {
    data: {
      default: () => {},
    },
    indexSection: {
      default: () => {},
    },
    indexQuestion: {
      default: () => {},
    },
    sectionId: {
      default: () => {},
    },
  },
  data() {
    return {
      rankingOption: [],
      answerRanking: [],
      isLoad: false,
    }
  },
  mounted() {
    // if (this.data.answer == '' || this.data.answer == null) {
    //   this.data.answer = []
    // }
    this.isLoad = true
    this.data.answerList.map(answer => {
      function compareQuestion(a, b) {
        if (a.indexQuestion < b.indexQuestion) return -1
        if (a.indexQuestion > b.indexQuestion) return 1
        return 0
      }
      if (answer.indexQuestion > 0) {
        this.rankingOption.sort(compareQuestion)
        this.rankingOption.push(answer)
      }

      function compareAnswer(a, b) {
        if (a.indexQuestion < b.indexQuestion) return -1
        if (a.indexQuestion > b.indexQuestion) return 1
        return 0
      }
      if (answer.indexAnswer > 0) {
        this.answerRanking.sort(compareAnswer)
        this.answerRanking.push(answer)
      }
    })

    if (this.answerRanking.length == 0) {
      this.isLoad = false
    }
  },
  watch: {
    answerRanking(val) {
      if (!this.isLoad) {
        this.submit(val)
      } else {
        this.isLoad = false
      }
    },
  },
  methods: {
    submit(val) {
      let data = {
        newSectionId: this.sectionId,
        newQuestionId: this.data.id,
        newAnswerId: val,
        description: null,
        rating: 0,
        indexQuestion: this.rankingOption,
        indexAnswer: this.answerRanking,
      }

      this.$emit('clicked', data)
    },
    copyText() {
      const selection = document.getSelection()

      this.$emit('copy-text', selection.toString())
    },
    checkRulesData(isRequired, minimumNumber, maximumNumber) {
      let rules = ``
      if (isRequired) {
        rules = `requiredarray`
      }

      if (minimumNumber != null) {
        if (rules != ``) {
          rules = rules + `|`
        }
        rules = rules + `checkboxmin:${minimumNumber}`
      }

      if (maximumNumber != null) {
        if (rules != ``) {
          rules = rules + `|`
        }
        rules = rules + `checkboxmax:${maximumNumber}`
      }

      return rules
    },
  },
}
</script>
<style scoped>
.border-ranking-left {
  /* border: 1px solid black; */
  width: 48% !important;
  background-color: #ebebeb;
  margin-right: 2%;
}
.border-ranking-right {
  border: 1px solid #7e7e7e;
  width: 49% !important;
}
.message-body-data {
  color: #7e7e7e;
  font-size: 12px;
}
.box-answer {
  /* border: 1px solid black; */
  margin-right: 3%;
  margin-bottom: 1%;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
  color: #4a4a4a;
  padding-top: 0.25px;
  padding-bottom: 0.25px;
  padding-left: 1rem;
  padding-right: 0.5rem;
  /* display: block; */
}
</style>
