<template>
  <div>
    <ValidationProvider
      :vid="`starRatingType${indexSection}${indexQuestion}`"
      name="Star Rating"
      :rules="{ required: data.isRequired }"
      v-slot="{ errors }"
    >
      <b-rate v-model="rate" :max="data.answerList[0].rightSideNumber"></b-rate>
      <span class="required">{{ errors[0] }}</span>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
    indexSection: {
      default: () => {},
    },
    indexQuestion: {
      default: () => {},
    },
    sectionId: {
      default: () => {},
    },
  },
  data() {
    return {
      rate: 0,
      isLoad: false,
    }
  },
  mounted() {
    this.isLoad = true
    this.rate =
      this.data.answerList[0].description == null
        ? null
        : parseInt(this.data.answerList[0].description)
    if (this.rate == null) {
      this.isLoad = false
    }
  },
  watch: {
    rate() {
      if (!this.isLoad) {
        this.submit()
      } else {
        this.isLoad = false
      }
    },
  },
  methods: {
    submit() {
      let data = {
        newSectionId: this.sectionId,
        newQuestionId: this.data.id,
        newAnswerId: this.data.answerList[0].id,
        description: null,
        rating: this.rate,
        indexQuestion: 0,
        indexAnswer: 0,
      }

      this.$emit('clicked', data)
    },
  },
}
</script>
