<template>
  <div>
    <div class="columns is-multiline is-gapless">
      <div class="column is-12">
        <template v-if="data.isButton">
          <ValidationProvider
            :vid="`radiobuttontype${indexSection}${indexQuestion}`"
            name="Radio Button"
            :rules="`${data.isRequired ? 'required' : ''}`"
            v-slot="{ errors }"
          >
            <div class="columns is-multiline is-gapless image-selector">
              <div
                v-for="(option, indexOption) in data.answerList"
                :key="indexOption"
                :class="option.type == 'text' ? 'column' : 'column is-narrow'"
              >
                <template v-if="option.type == 'text'">
                  <b-radio-button
                    type="is-hcc"
                    size="is-medium"
                    outlined
                    expanded
                    v-model="selectedOption"
                    :native-value="option.id"
                    @copy="copyText()"
                    @input="checkRulesRadioButton(option)"
                    @copy-text="copyText('copy', $event)"
                  >
                    {{ option.description }}
                  </b-radio-button>
                </template>
                <template v-else-if="option.type == 'image'">
                  <input
                    :id="option.id"
                    type="radio"
                    v-model="selectedOption"
                    :value="option.id"
                    @input="checkRulesRadioButton(option)"
                    v-show="false"
                  />
                  <label
                    class="my-radio-image"
                    :for="option.id"
                    style="
                      max-height: 250px;
                      max-width: 250px;
                      background-size: auto;
                      object-fit: cover;
                    "
                    :style="
                      getSizeImage(option.id) >= 250
                        ? 'width: 100%;'
                        : 'width: auto;'
                    "
                  >
                    <img
                      :id="`image-${option.id}`"
                      :src="getUrlFile(option.imagePath)"
                      alt=""
                      style="max-width: 250px"
                    />
                  </label>
                </template>
              </div>
            </div>
            <span class="required">{{ errors[0] }}</span>
          </ValidationProvider>
        </template>
        <template v-else>
          <ValidationProvider
            :vid="`radiobuttontype${indexSection}${indexQuestion}`"
            name="Radio Button"
            :rules="`${data.isRequired ? 'required' : ''}`"
            v-slot="{ errors }"
          >
            <div :style="differentiateStyle(data.answerList)">
              <div
                v-for="(option, indexOption) in data.answerList"
                :key="indexOption"
              >
                <div class="columns">
                  <div class="column is-12 image-selector">
                    <template
                      v-if="option.type == 'text'"
                      @copy="copyText('copy', $event)"
                    >
                      <b-radio
                        type="is-hcc"
                        v-model="selectedOption"
                        :native-value="option.id"
                        @input="checkRulesRadioButton(option)"
                      >
                        {{ option.description }}
                      </b-radio>
                    </template>
                    <template v-else-if="option.type == 'image'">
                      <b-radio
                        type="is-hcc"
                        v-model="selectedOption"
                        :native-value="option.id"
                        @input="checkRulesRadioButton(option)"
                      >
                        <img
                          :src="getUrlFile(option.imagePath)"
                          alt="Image"
                          :style="
                            getSizeImage(option.id) >= 200
                              ? 'width: 200px;'
                              : 'width: auto;'
                          "
                        />
                      </b-radio>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <span class="required">{{ errors[0] }}</span>
          </ValidationProvider>
        </template>
      </div>
      <!-- For True Answer -->
      <template v-if="data.isExample && selectedOption">
        <div v-if="isAnswerTrue">
          <div @copy="copyText()" v-html="data.trueAnswer"></div>
        </div>
        <div v-else>
          <div @copy="copyText()" v-html="data.falseAnswer"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
    indexSection: {
      default: () => {},
    },
    indexQuestion: {
      default: () => {},
    },
    type: {
      default: () => {},
    },
    sectionId: {
      default: () => {},
    },
  },
  data() {
    return {
      selectedOption: null,
      isLoad: false,
      isAnswerTrue: false,
    }
  },
  mounted() {
    this.isLoad = true
    this.data.answerList.map(answer => {
      if (answer.isAnswer == true) {
        this.selectedOption = answer.id
        if (answer.isValid == true) {
          this.isAnswerTrue = true
        }
      }
    })
    if (this.selectedOption == null) {
      this.isLoad = false
    }
  },
  watch: {
    selectedOption(val) {
      if (!this.isLoad) {
        this.submit(val)
      } else {
        this.isLoad = false
      }
    },
  },
  methods: {
    submit(val) {
      let data = {
        newSectionId: this.sectionId,
        newQuestionId: this.data.id,
        newAnswerId: val,
        description: null,
        rating: 0,
        indexQuestion: 0,
        indexAnswer: 0,
      }

      this.$emit('clicked', data)
    },
    getUrlFile(file) {
      return process.env.VUE_APP_BACKEND_URL + file
    },
    getSizeImage(id) {
      let image = document.getElementById('image-' + id)

      if (image != null) {
        image = image.naturalWidth
      }

      return image
    },
    differentiateStyle(arr) {
      let type = arr[0].type
      if (type == 'image') {
        return 'display:flex'
      } else {
        return ''
      }
    },
    copyText() {
      const selection = document.getSelection()

      this.$emit('copy-text', selection.toString())
    },

    checkRulesRadioButton(option) {
      if (option.isValid) {
        this.isAnswerTrue = true
      } else {
        this.isAnswerTrue = false
      }
    },
  },
}
</script>

<style>
.image-selector div input {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.image-selector div input:active + .my-radio-image {
  opacity: 0.9;
}
.image-selector div input:checked + .my-radio-image {
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
  outline: 5px solid #00a3a3;
  outline-offset: -4px;
  height: auto;
}

.b-radio.radio.is-medium {
  height: auto;
  font-size: 1.25rem;
  white-space: normal;
}
.my-radio-image {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 100px;
  height: 70px;
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  -webkit-filter: grayscale(1) opacity(0.7);
  -moz-filter: grayscale(1) opacity(0.7);
  filter: grayscale(1) opacity(0.7);
}
/* @media screen and (min-width: 1216px) {
  .my-radio-image {
    height: 200px !important;
  }
}
@media screen and (min-width: 1408px) {
  .my-radio-image {
    height: 300px !important;
  }
} */
.my-radio-image:hover {
  -webkit-filter: grayscale(0.5) opacity(0.9);
  -moz-filter: grayscale(0.5) opacity(0.9);
  filter: grayscale(0.5) opacity(0.9);
  outline: 5px solid #00a3a3;
  outline-offset: -4px;
  height: auto;
}

/* Extras */
a:visited {
  color: #888;
}
a {
  color: #444;
  text-decoration: none;
}
p {
  margin-bottom: 0.3em;
}

.image-selector div input {
  margin: 5px 0 0 12px;
}
.image-selector div label {
  margin-left: 7px;
}
span.cc {
  color: #6d84b4;
}
</style>
